import LinearProgress from '@mui/material/LinearProgress';
import addDays from 'date-fns/addDays';
import isWithinInterval from 'date-fns/isWithinInterval';
import subDays from 'date-fns/subDays';
import orderBy from 'lodash/orderBy';
import React from 'react';
import { AppContext } from '../../contexts/app-context';
import { AuthContext } from '../../contexts/auth-context';
import { usePermissions } from '../../hooks/use-permissions';
import { ARC_GROUP, EventData } from '../../types';

const Components = {
  AdminBar: React.lazy(() => import('./admin-bar')),
  AgentBar: React.lazy(() => import('./agent-bar')),
};

export function Arrival() {
  // Context
  const appContext = React.useContext(AppContext);
  const authContext = React.useContext(AuthContext);
  // State
  const [eventsData, setEventsData] = React.useState<EventData[]>([]);
  // Hooks
  const { checkPermissions } = usePermissions();

  React.useEffect(() => {
    if (appContext.state.eventsData.length) {
      const newEvents = appContext.state.eventsData.filter((i) => {
        if (i.DateBegin && i.DateEnd) {
          const DateBegin = subDays(new Date(i.DateBegin), 3);
          const DateEnd = addDays(new Date(i.DateEnd), 2);
          return isWithinInterval(new Date(), {
            start: DateBegin,
            end: DateEnd,
          });
        } else {
          return false;
        }
      });

      if (newEvents.length) {
        setEventsData(orderBy(newEvents, 'DateBegin'));
      }
    }
  }, [appContext.state.eventsData]);

  const isAdmin = checkPermissions([
    ARC_GROUP.AllianceEvents_Admin,
    ARC_GROUP.AllianceEvents_CheckIn,
  ]);
  const isAuthenticated = authContext.state.user !== undefined;

  if (eventsData.length && isAdmin) {
    return (
      <React.Suspense fallback={<LinearProgress color="primary" />}>
        <Components.AdminBar data={eventsData} />
      </React.Suspense>
    );
  } else if (eventsData.length && isAuthenticated) {
    return (
      <React.Suspense fallback={<LinearProgress color="primary" />}>
        <Components.AgentBar data={eventsData} />
      </React.Suspense>
    );
  } else {
    return null;
  }
}
