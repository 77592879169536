import { ARC_GROUP } from '../../types';

export interface NavDataLink {
  title: string;
  // to - for internal routing
  to: string;
  // User must be authenticated
  authenticated?: boolean;
  // User must have one of the permissions
  permissions?: ARC_GROUP[];
}

export const navData: NavDataLink[] = [
  {
    title: 'Home',
    to: '/',
  },
  {
    title: 'Events',
    to: '/events',
  },
  {
    title: 'Trips',
    to: '/trips',
  },
  {
    title: 'Hotspots',
    to: '/hotspots',
  },
  {
    title: 'Tickets',
    to: '/account/tickets',
    authenticated: true,
  },
  {
    title: 'Admin',
    to: '/admin/info',
    authenticated: true,
    permissions: [ARC_GROUP.AllianceEvents_Admin],
  },
];
